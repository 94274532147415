<template>
  <v-list-item-content @click="onClick" v-if="member">
    <v-list-item-title>
      {{ member.email }}
    </v-list-item-title>
    <v-list-item-subtitle>
      <span v-if="getUserFullName()">
        {{ getUserFullName() }}
      </span>
      <span v-else>
        <span v-if="nameLoading"> Loading... </span>
        <span v-else>
          <slot name="prefix"></slot>
          <slot
            name="default"
            :user="userInfo"
            :email="userInfo ? userInfo.email : ''"
            :fullName="fullName(userInfo)"
          >
            <span> {{ fullName(userInfo) }} </span>
          </slot>
        </span>
      </span>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import config from '../../config.js';
import { fullName } from '../../utils';
const api = config.api();
import _ from 'lodash';
export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    member: {
      type: Object,
      default: null
    },
    uid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nameLoading: true,
      userInfo: null,
      name: ''
    };
  },
  created() {
    this.fetchUser();
  },
  methods: {
    onClick() {
      if (this.editable) {
        this.$emit('click');
      }
    },
    fullName,
    getUserFullName() {
      return this.member.fullName || this.name;
    },
    buildURL() {
      let res = `${api.cloudFunction}/`;
      if (this.member?.email) {
        res += `userInfo?email=${this.member?.email}`;
      } else if (this.uid) {
        res += `userInfo?uid=${this.uid}`;
      }
      return res;
    },
    fetchUser() {
      if (this.getUserFullName()) {
        return;
      }
      if (!this.member.email && !this.uid) {
        return;
      }
      this.$axios
        .get(this.buildURL())
        .then(res => {
          if (res.status === 200 && res.data.data) {
            res.data.data.users.forEach(u => {
              if (this.member.email) {
                if (u.email === this.member.email) {
                  this.name = fullName(u);
                }
              } else if (this.uid) {
                this.userInfo = u;
              }
            });
          }
        })
        .finally(() => {
          this.nameLoading = false;
        });
    }
  }
};
</script>

<style>
</style>
